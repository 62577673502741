/*
 * Component: Callout
 * ------------------
 */

// Base styles (regardless of theme)
.callout {
  .border-radius(0px);
  margin: 0 0 20px 0;
  padding: 15px 30px 15px 15px;
  border-left: 5px solid #eee;
  a {
    color: #fff;
    text-decoration: underline;
    &:hover {
      color: #eee;
    }
  }
  h4 {
    margin-top: 0;
    font-weight: 600;
  }
  p:last-child {
    margin-bottom: 0;
  }
  code,
  .highlight {
    background-color: #fff;
  }

  // Themes for different contexts
  &.callout-danger {
    color: darken(@red, 10%);
    background-color: lighten(@red, 30%);
    border-color: lighten(@red, 10%);
  }
  &.callout-warning {
    color: darken(@brand-warning, 10%);
    background-color: lighten(@brand-warning, 40%);
    border-color: lighten(@brand-warning, 20%);
  }
  &.callout-info {
  	color: darken(@brand-info, 10%);
    background-color: lighten(@brand-info, 40%);
    border-color: lighten(@brand-info, 20%);
  }
  &.callout-success {
    color: darken(@brand-success, 10%);
    background-color: lighten(@brand-success, 30%);
    border-color: lighten(@brand-success, 20%);
  }
}
