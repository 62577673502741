/*
 * Component: alert
 * ----------------
 */

.alert {
  .border-radius(0px);
  h4 {
    font-weight: 600;
  }
  .icon {   
    margin-right: 10px;    
  }
  .close {
    color: #000;
    .opacity(.2);
    &:hover {
      .opacity(.5);
    }
  }
  a {
    color: #fff;
    text-decoration: underline;
  }
}

//Alert Variants
.alert-success {
  color: darken(@brand-success, 10%);
    background-color: lighten(@brand-success, 30%);
    border-color: lighten(@brand-success, 20%);
}
.alert-danger,
.alert-error {
  color: darken(@red, 10%);
    background-color: lighten(@red, 30%);
    border-color: lighten(@red, 10%);
}
.alert-warning {
  color: darken(@brand-warning, 10%);
    background-color: lighten(@brand-warning, 40%);
    border-color: lighten(@brand-warning, 20%);
}
.alert-info {
  color: darken(@brand-info, 10%);
	background-color: lighten(@brand-info, 40%);
	border-color: lighten(@brand-info, 20%);
}
