.radio label,
.checkbox label {
  padding-left: 0;
}
.icheckbox_square-blue,
.iradio_square-blue {
  margin-right: 5px;
}
.bootstrap-select .btn-default {
  background-color: #ffffff;
}
.bootstrap-select .btn-default:hover,
.bootstrap-select .btn-default:focus,
.bootstrap-select .btn-default.focus,
.bootstrap-select .btn-default:active,
.bootstrap-select .btn-default.active,
.bootstrap-select.open > .dropdown-toggle.btn-default {
  background-color: #ffffff !important;
  border-color: #3c8dbc !important;
}
.bootstrap-select.open {
  border-color: #3c8dbc !important;
}
.bootstrap-select.btn-group > .disabled {
  background-color: #efefef !important;
}
